.blogWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @media screen and (min-width: 767px) {
        margin: 0 -1rem;
    }
    .blogItem {
        flex: 1;
        flex: 0 0 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 1rem;
        &:first-child {
            margin-top: 0;
        }
        h2 {
            font-size: 2em;
            margin-top: 0;
            a {
                text-decoration: none;
            }
            @media screen and (min-width: 767px) {
                font-size: 2.5rem;
            }
        }
        p {
            max-width: initial !important;
        }
        @media screen and (min-width: 767px) {
            flex-direction: row;
            margin-top: 2rem;
            &:nth-child(2n) {
                flex-direction: row-reverse;
            }
            & > * {
                padding: 0 1rem;
                flex: 1;
            }
        }
    }
}