@import './hamburgers.min.css';
@import 'header';

$main-color: #00BA8B;
$font-color: #ffffff;

body {
    background-color: $main-color;
    color: $font-color;
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
    line-height: 2.15rem;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'VeneerTwo', sans-serif;
    font-weight: 400;
}

* {
    box-sizing: border-box;
}

a {
    color: $font-color;
}

p {
    @media screen and (min-width: 992px) {
        margin: 2rem 0;
    }
}

.btn {
    padding: .5rem 1rem;
    display: inline-block;
    border: 1px solid $font-color;
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    color: $font-color;
    transition: .2s ease-in-out;
    &:disabled {
        opacity: .5;
        color: #ffffff !important;
        cursor: not-allowed;
        &:hover {
            background-color: transparent !important;
            color: #ffffff !important;
        }
    }
    &:hover {
        background-color: $font-color !important;
        color: $main-color !important;
    }
}

.menu {
    margin: 0;
    padding: 0;
    list-style: none;
    &.horizontal {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row1;
    }
    a {
        text-transform: uppercase;
    }
}

input, textarea {
    background-color: transparent;
    border: 0 none;
    border-bottom: 1px solid $font-color;
    padding: 1rem;
    color: $font-color;
    width: 100%;
    &::placeholder {
        color: #ffffff;
        opacity: 1;
        text-transform: uppercase;
    }
      
    &:-ms-input-placeholder {
        color: #ffffff;
        text-transform: uppercase;
    }
      
    &:-ms-input-placeholder {
        color: #ffffff;
        text-transform: uppercase;
    }
}

.form-group {
    margin-top: 1rem;
    &:first-child {
        margin-top: 0;
    }
}

.container {
    position: relative;
    max-width: 1115px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}

.container-fluid {
    padding: 0 1rem;
    width: 100%;
    max-width: 100%;
    position: relative;
}

.row {
    position: relative;
    margin: 0 -1rem;
    display: flex;
    flex-wrap: wrap;
    & > * {
        padding: 0 1rem;
    }
    .half {
        flex: 0 0 100%;
        @media screen and (min-width: 767px) {
            flex: 0 0 50%;
        }
    }
    .col {
        flex: 1;
    }
    &.justify-between {
        justify-content: space-between;
    }
}

.headline {
    line-height: normal;
    margin: 1rem 0;
    @media screen and (min-width: 767px) {
        margin: 2rem 0;
    }
    h1, h2, h3 {
        margin: 0;
        text-transform: uppercase;
    }
    h1, h2 {
        font-size: 2.5rem;
        @media screen and (min-width: 1200px) {
            font-size: 7rem;
            margin-top: -2rem;
        }
    }
    h3 {
        font-size: 1.5rem;
        @media screen and (min-width: 1200px) {
            font-size: 6.313rem;
        }
    }
}

#intro {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    &:before {
        position: absolute;
        width: 50%;
        height: 100%;
        background-color: $main-color;
        content: "";
        z-index: 2;
        display: none;
        @media screen and (min-width: 767px) {
            display: block;
        }
    }
    #spinner-wrapper {
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(72%) translateX(-50%) scale(1);
        transition: .2s ease-in-out;
        &.is-active {
            transform: scale(3);
        }
        #spinner {
            background: url(../images/mohn-circle.svg) no-repeat center center / cover;
            height: 800px;
            width: 800px;
            animation: App-logo-spin infinite 300s linear;
        }
        @media screen and (min-width: 480px) {
            transform: translateY(25%) translateX(-50%) scale(.75);
        }
        @media screen and (min-width: 767px) {
            top: 50%;
            transform: translateX(-30%) translateY(-50%) scale(.75);
            right: initial;
            margin: initial;
            bottom: initial;
            #spinner {
                height: 1200px;
                width: 1200px;
                animation: App-logo-spin infinite 200s linear;
            }
        }
        @media screen and (min-width: 992px) {
            transform: translateY(-50%) scale(1.3);
        }
    }
    .container {
        z-index: 4;
        height: 100%;
    }
    & > img {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        object-fit: cover;
        height: 100%;
    }
    .caption {
        position: absolute;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        left: 0;
        padding: 0 1rem;
        padding-bottom: 3rem;
        a {
            display: none;
            @media screen and (min-width: 767px) {
                display: inline-block;
            }
        }
        p {
            margin: initial;
            text-align: center;
            @media screen and (min-width: 767px) {
                margin: inherit;
                text-align: left;
            }
        }
        @media screen and (min-width: 767px) {
            top: 50%;
            transform: translateY(-50%);
            width: 87%;
            bottom: initial;
            padding-bottom: 0;
        }
    }
    &.kontakt {
        .container {
            overflow-y: auto;
        }
    }
}

#content {
    &:not(.startseite, .kontakt) {
        padding-top: 132px;
        padding-bottom: 50px;
        @media screen and (min-width: 992px) {
            padding-top: 190px;
            padding-bottom: 97px;
        }
        p {
            @media screen and (min-width: 992px) {
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &.kontakt {
        #intro {
            &:before {
                position: fixed;
            }
            #spinner-wrapper {
                position: fixed;
            }
        }
        p {
            margin: .5rem 0;
        }
        .container {
            overflow-y: auto;
            form {
                @media screen and (min-width: 767px) {
                    max-width: 70%;
                }
            }
        }
        .headline {
            padding-top: 145px;
        }
        #intro {
            background-color: #00BA8B;
            & > img {
                display: none;
                @media screen and (min-width: 767px) {
                    display: block;
                }
            }
            .container {
                padding-bottom: 60px;
                @media screen and (min-width: 767px) {
                    padding-bottom: 0;
                }
            }
            #spinner-wrapper {
                display: none;
                @media screen and (min-width: 767px) {
                    display: block;
                }
            }
        }
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.partner-inner {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    a {
        border: 4px solid #ffffff;
        display: block;
        height: 100%;
        width: 100%;
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        min-height: 150px;
    }
}

.App {
    position: relative;
    overflow-x: hidden;
    &:not(.startseite), &:not(.kontakt) {
        .hamburger {    
            .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
                background-color: #ffffff;
            }
        }
    }
}

#footer {
    padding-top: .5rem;
    padding-bottom: .5rem;
    text-transform: uppercase;
    text-align: center;
    @media screen and (min-width: 767px) {
        text-align: left;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    &.light {
        position: absolute;
        bottom: 0;
        z-index: 4;
        width: 100%;
    }
    &.dark {
        background-color: #333333;
    }
    .row {
        align-items: center;
        flex-direction: column;
        @media screen and (min-width: 767px) {
            flex-direction: row;
        }
        & > * {
            width: 100%;
            flex: 1;
        }
    }
}

#footer-menu {
    ul {
        margin: 0 -1rem;
        justify-content: center;
        @media screen and (min-width: 767px) {
            justify-content: flex-end;
        }
        li {
            padding: 0 1rem;
        }
    }
}

.img-responsive {
    display: block;
    width: 100%;
    position: relative;
    max-width: 100%;
}

.hamburger {
    margin-right: 1rem;
    @media screen and (min-width: 767px) {
        display: none;
    }
    &.is-active {
        opacity: 1 !important;
        .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
            background-color: #ffffff;
        }
    }
    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        border-radius: 0;
        background-color: #00BA8B;
    }
}

@import url("//hello.myfonts.net/count/402ab1");
  
@font-face {
  font-family: "VeneerTwo";
  src: url('../fonts/font.woff2') format('woff2'), url('../fonts/font.woff') format('woff');
}