.serviceWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 -1rem 3rem -1rem;
    @media screen and (min-width: 767px) {
        margin: 0 -1rem 6rem -1rem;
    }
    .serviceItem {
        flex: 0 0 100%;
        width: 100%;
        padding: 0 1rem;
        margin-top: 2rem;
        &:first-child {
            margin-top: 0;
        }
        @media screen and (min-width: 767px) {
            flex: 0 0 50%;
            width: 50%;
            &:nth-child(-n+2) {
                margin-top: 0;
            }
        }
        h3 {
            margin: 0;
            font-size: 2.5rem;
            line-height: 2.5rem;
            @media screen and (min-width: 767px) {
                font-size: 3rem;
                line-height: 3rem;
            }
        }
        .serviceItemImage {
            img {
                width: 100%;
                max-width: 100%;
                display: block;
                object-fit: cover;
                position: relative;
            }
        }
    }
}