#menu-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    max-width: 100%;
    @media screen and (min-width: 767px) {
        justify-content: initial;
    }
    &:not(.startseite, .kontakt) {
        background-color: #00BA8B;
        &.is-active {
            box-shadow: 0 0 20px rgba(0,0,0,.25);
            border-bottom: 1px solid rgba(0,0,0,.1);
        }
    }
    &.is-active {
        position: fixed;
        #logo {
            padding-top: 10px;
            padding-bottom: 10px;
            img {
                @media screen and (min-width: 992px) {
                    max-width: 80px;
                }
            }
        }
    }
    #menu {
        display: none;
        @media screen and (min-width: 767px) {
            display: block;
        }
    }
    #logo {
        margin-right: 1rem;
        padding: 20px;
        a {
            display: block;
            img {
                transition: .2s ease-in-out;
                max-width: 80px;
                @media screen and (min-width: 992px) {
                    max-width: 130px;
                }
            }
        }
    }
    ul {
        li {
            a {
                text-decoration: none;
                padding: 1rem;
            }
        }
    }
    #social-nav {
        position: absolute;
        right: 0;
        padding: 20px;
        display: none;
        @media screen and (min-width: 767px) {
            display: block;
        }
        a {
            display: block;
            padding: 0 .5rem;
        }
        img {
            max-width: 51px;
        }
    }
}

#mobile-menu {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #00BA8B;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-align: center;
    transform: translateX(100%);
    transition: .2s ease-in-out;
    padding-top: 140px;
    overflow-y: auto;
    @media screen and (min-width: 767px) {
        display: none;
    }
    &.is-active {
        transform: translateX(0);
    }
    a {
        font-size: 2rem;
        line-height: 3rem;
        text-decoration: none;
    }
    #mobile-social-nav {
        margin-top: 1rem;
        & > ul {
            margin: 0 -.25rem;
            & > li {
                padding: 0 .25rem;
            }
        }
    }
}